<template>
  <div id="app">
    <div class="lg:block hidden">
      <a-layout :class="{'bg-blue-100': $route.name === 'Billing' || $route.name === 'Upgrade'}">
        <a-layout-header class="header-block" v-if="showLayout && showHeader">
          <TheHeader/>
        </a-layout-header>
        <a-layout-content>
          <VNotification v-if="showLayout && alert" :data="alert"></VNotification>
          <div
              class="flex"
              :class="{
							'main-layout m-auto max-w-screen-xl': showLayout,
							'min-h-screen': !showLayout,
							'main-layout_footer-only': !showHeader,
						}"
          >
            <router-view></router-view>
          </div>
        </a-layout-content>
        <a-layout-footer v-if="showLayout && !showSurveyFooter">
          <TheFooter/>
        </a-layout-footer>
        <TheSurveyFooter v-else-if="showLayout && showSurveyFooter"/>
      </a-layout>
    </div>
    <div class="lg:hidden flex absolute inset-0">
      <div class="blured-bg-image z-10"></div>
      <div class="text-center m-auto bg-white rounded-lg z-20 px-10 py-8">
        <p class="text-2xl mb-4">Please switch to your Desktop to start using the Survey Builder!</p>
        <VButton type="primary" size="large" @click="redirectToLanding" class="mx-auto">
          Visit MetaSurvey website
        </VButton>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import {mapGetters} from "vuex";
import {isEmpty} from "lodash";
import {SurveyBotFail} from "@/helpers/customIcons";
import VNotification from "@/components/VNotification";
import TheSurveyFooter from "@/components/SurveyEdit/Design/TheSurveyFooter";
import VButton from "./components/VButton";

export default {
  name: 'App',
  components: {TheHeader, TheFooter, VNotification, TheSurveyFooter, VButton},
  data() {
    return {
      failIcon: SurveyBotFail,
      landingUrl: process.env.VUE_APP_LANDING_URL,
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'responsesLimit', 'alert']),
    showLayout() {
      return !isEmpty(this.user) && this.$route.name !== 'Login' && this.$route.name !== 'Register' && this.$route.name !== 'ChurnSurvey'
    },
    showHeader() {
      return this.$route.name !== 'NotFound';
    },
    showSurveyFooter() {
      return this.$route.name === 'Survey' && this.$route.query.tab !== 'analytic' && this.$route.query.tab !== 'comments';
    },
  },
  methods: {
    redirectToLanding: function () {
      window.location.href = this.landingUrl;
    }
  }
}
</script>

<style lang="less">
.main-layout {
  min-height: calc(100vh - 126px);

  &_footer-only {
    min-height: calc(100vh - 46px);
  }
}

.blured-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url('./assets/images/mobile-version-bg.jpg') 50% 50% no-repeat;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
