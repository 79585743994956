<template>
	<VAuthBlock title="Set new password">
		<div v-if="!resetSuccess">
			<a-form :form="form" @submit="reset">
				<a-form-item :validate-status="passwordError() ? 'error' : ''" :help="passwordError() || ''">
					<a-input-password
						size="large"
						placeholder="New password"
						v-decorator="['password', { rules: [{ required: true, message: 'Please input your Password' }, {min: 8, message: 'Try a password with at least 8 characters'}, {validator: validateToNextPassword}] }]"
					>
						<a-icon slot="prefix" type="lock" style="color: var(--color-gray-300)"/>
					</a-input-password>
				</a-form-item>
				<a-form-item :validate-status="confirmError() ? 'error' : ''" :help="confirmError() || ''">
					<a-input-password
						size="large"
						placeholder="Confirm password"
						v-decorator="['confirm', { rules: [{ required: true, message: 'Please confirm your Password' }, {validator: compareToFirstPassword}] }]"
						@blur="handleConfirmBlur"
					>
						<a-icon slot="prefix" type="lock" style="color: var(--color-gray-300)"/>
					</a-input-password>
				</a-form-item>
				<a-form-item>
					<div class="flex items-start justify-between mt-8">
						<a-button
							type="primary"
							size="large"
							class="btn-action"
							html-type="submit"
							:disabled="hasErrors(form.getFieldsError())"
							:loading="isLoading"
						>
							Set new password
						</a-button>
					</div>
				</a-form-item>
			</a-form>
		</div>
		<div
			v-else
			class="text-base mt-16"
		>
			Your password was successfully updated! Now go to the
			<router-link :to="{name: 'Login'}">Log in</router-link>
			page.
		</div>
	</VAuthBlock>
</template>

<script>
import VAuthBlock from "@/components/VAuthBlock";
import hasErrors from "@/helpers/formValidate";
import {mapGetters} from "vuex";
import {RESET_PASSWORD} from "@/store/actions.type";

export default {
	name: "ResetPassword",
	components: {VAuthBlock},
	data() {
		return {
			resetSuccess: false,
			confirmDirty: false,
			hasErrors,
			form: this.$form.createForm(this, {name: 'reset_password_form'}),
		};
	},
	mounted() {
		this.$nextTick(() => {
			// To disabled submit button at the beginning.
			this.form.validateFields();
		});
	},
	computed: {
		...mapGetters('auth', ['isLoading'])
	},
	methods: {
		// Only show error after a field is touched.
		passwordError() {
			const {getFieldError, isFieldTouched} = this.form;
			return isFieldTouched('password') && getFieldError('password');
		},
		// Only show error after a field is touched.
		confirmError() {
			const {getFieldError, isFieldTouched} = this.form;
			return isFieldTouched('confirm') && getFieldError('confirm');
		},
		handleConfirmBlur(e) {
			const value = e.target.value;
			this.confirmDirty = this.confirmDirty || !!value;
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form;
			if (value && value !== form.getFieldValue('password')) {
				callback('Two passwords that you enter is inconsistent!');
			} else {
				callback();
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form;
			if (value && this.confirmDirty) {
				form.validateFields(['confirm'], {force: true});
			}
			callback();
		},
		reset(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					//Get reset token
					values.token = this.$route.params.token;
					this.$store.dispatch(`auth/${RESET_PASSWORD}`, values)
						.then(() => {
							this.resetSuccess = true;
						})
						.catch(() => {
							this.resetSuccess = false;
						})
				}
			});
		},
	},
}
</script>

<style scoped>

</style>
