<template>
  <div>
    <div class="flex items-start pl-12">
      <div class="flex flex-grow">
        <span class="text-xl font-light">Question:</span>
        <VTextArea :max-length="questionMaxLength"
                   :count-typed-symbols="question.text.length"
                   :has-upgrade="true"
                   class="flex-grow ml-3">
          <a-textarea placeholder="Your question here"
                      :max-length="questionMaxLength"
                      :auto-size="{ minRows: 3 }"
                      v-model="question.text"
          />
        </VTextArea>
      </div>
      <div class="flex ml-5 flex-none">
        <div class="flex items-center">
          <span class="text-xl font-light">Scale</span>
          <a-tooltip class="ml-1 mr-2">
            <template slot="title">
              Number of points.
            </template>
            <a-icon type="info-circle" class="icon-info"/>
          </a-tooltip>
        </div>
        <a-select v-model="question.options.scoreScale" style="width: 80px" size="large">
          <a-select-option
              v-for="scaleVal in scales"
              :key="scaleVal"
          >
            {{ scaleVal }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="flex mt-3">
      <div class="w-32 flex justify-end items-center mr-5 font-light text-lg">
        Preview
        <a-icon type="eye" class="ml-1 text-2xl"
                style="color: var(--color-neutral-gray-300)"></a-icon>
      </div>
      <div>
        <div class="flex space-x-3">
          <div class="bg-gray-100 text-gray-500 rounded-full py-4 px-5 mt-0 leading-none"
               v-for="n in arScoreScale"
               :key="n"
          >
            <span class="font-semibold text-lg">{{ n }}</span>
          </div>
        </div>
        <div class="flex justify-between w-full mt-4">
          <div>
            <span v-if="showMinLabel"
                  class="text-base text-character_gray-200 dark:text-white dark:text-opacity-70">
              {{ minLabel }}
            </span>
          </div>
          <div>
            <span v-if="showMaxLabel"
                  class="text-base text-character_gray-200 dark:text-white dark:text-opacity-70">
              {{ maxLabel }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <a-divider style="margin-top: 32px; margin-bottom: 16px; border-color: var(--color-gray-300)"/>

    <div class="extra-collapse">
      <a-collapse :bordered="false" expandIconPosition="right">

        <template #expandIcon="props">
          <a-icon type="down"
                  :rotate="props.isActive ? 180 : 0"
                  style="color: var(--color-text-description); font-size: 16px;"/>
        </template>

        <a-collapse-panel key="1">

          <template slot="header">
            <div class="flex items-center space-x-1.5 text-xl leading-none">
              <VIcon name="translation" style="color: var(--color-text-secondary)"/>
              <span class="text-character_gray-400 border-0 border-b border-dashed border-character_gray-400">
                  Change min-max labels
                </span>
            </div>
          </template>

          <div class="flex flex-wrap gap-x-8 gap-y-4 pt-6">
            <VSwitchInput input-name="Min label"
                          input-info-html="<b>Min value label</b>. Show the min level of recommendation."
                          input-info-icon="tooltip-question-nps-min"
                          :input-length="20"
                          input-width="168px"
                          :input-placeholder="translationPlaceholder.min"
                          :input-value.sync="question.translations.minLabel.value"
                          :switch-value.sync="question.translations.minLabel.active"
            />

            <VSwitchInput input-name="Max label"
                          input-info-html="<b>Max value label</b>. Show the max level of recommendation."
                          input-info-icon="tooltip-question-nps-max"
                          :input-length="20"
                          input-width="168px"
                          :input-placeholder="translationPlaceholder.max"
                          :input-value.sync="question.translations.maxLabel.value"
                          :switch-value.sync="question.translations.maxLabel.active"
            />
          </div>

        </a-collapse-panel>
      </a-collapse>
    </div>

  </div>
</template>

<script>
import VTextArea from "@/components/VTextArea";
import questionMixin from "@/mixins/question";
import {range} from 'lodash';
import VIcon from "../../../VIcon";
import VSwitchInput from "../../../VSwitchInput";
import {mapGetters} from "vuex";

export default {
  name: "QuestionNps",

  props: ["question"],

  components: {
    VTextArea,
    VIcon,
    VSwitchInput,
  },

  mixins: [questionMixin],

  watch: {
    'question.options.scoreScale': function (score) {
      this.question.subtype = score > this.maxBreakScore ? 'vertical' : 'horizontal';
    }
  },

  data() {
    return {
      scales: [3, 4, 5, 6, 7, 8, 9, 10],
      maxBreakScore: 4,
    }
  },

  computed: {
    ...mapGetters('survey', ['survey']),
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 100;
    },
    arScoreScale: function () {
      return range(0, this.question.options.scoreScale + 1);
    },
    translationPlaceholder: function () {
      const min = this.survey.translations.questions.nps.minLabel
      const max = this.survey.translations.questions.nps.maxLabel

      return {
        min: min.value || min.defaultValue,
        max: max.value || max.defaultValue,
      }
    },
    minLabel: function () {
      return this.question.translations.minLabel.value || this.survey.translations.questions.nps.minLabel.value || this.survey.translations.questions.nps.minLabel.defaultValue;
    },
    maxLabel: function () {
      return this.question.translations.maxLabel.value || this.survey.translations.questions.nps.maxLabel.value || this.survey.translations.questions.nps.maxLabel.defaultValue;
    },
    showMinLabel: function () {
      return this.question.translations.minLabel.active
    },
    showMaxLabel: function () {
      return this.question.translations.maxLabel.active
    },
  },
}
</script>
