<template>
  <transition
      enter-active-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="opacity-100"
      leave-to-class="opacity-0"
  >
    <div
        v-if="welcomeScreen"
        v-show="welcomeScreen.isActive"
        class="flex items-start transition-opacity ease-in-out duration-300">
      <div v-if=" welcomeScreen.bgImage.isActive && survey.features.welcome_image">
        <h4 class="text-xl font-light mb-1">Custom background image:</h4>
        <VUpload
            class="mr-8 flex-none"
            uploader-type="welcome-screen"
            :image-src.sync="welcomeScreen.bgImage.src"
        />
      </div>
      <div class="flex-grow space-y-1">
        <div :class="{'flex': !welcomeScreen.bgImage.isActive}">
          <div :class="{'w-32 text-right': !welcomeScreen.bgImage.isActive}">
            <h4 class="text-xl font-light mb-1 mr-3">Title:</h4>
          </div>
          <div class="flex-grow">
            <VTextArea :max-length="50" :count-typed-symbols="welcomeScreen.title.length">
              <a-input
                  :placeholder="default_messages.title"
                  size="large"
                  :maxLength="50"
                  v-model="welcomeScreen.title"
              />
            </VTextArea>
          </div>
        </div>
        <div :class="{'flex': !welcomeScreen.bgImage.isActive}">
          <div :class="{'w-32 text-right': !welcomeScreen.bgImage.isActive}">
            <h4 class="text-xl font-light mb-1 mr-3">Description:</h4>
          </div>
          <div class="flex-grow">
            <VTextArea :max-length="questionMaxLength"
                       :count-typed-symbols="welcomeScreen.description.length"
                       :has-upgrade="true">
              <a-textarea :placeholder="default_messages.description"
                          :max-length="questionMaxLength"
                          :auto-size="{ minRows: 3 }"
                          v-model="welcomeScreen.description"
              />
            </VTextArea>
          </div>
        </div>
        <div :class="{'flex': !welcomeScreen.bgImage.isActive}">
          <div :class="{'w-32 text-right': !welcomeScreen.bgImage.isActive}">
            <h4 class="text-xl font-light mb-1 mr-3">Button label:</h4>
          </div>
          <div class="w-48">
            <VTextArea :max-length="20"
                       :count-typed-symbols="survey.translations.welcomeScreen.buttonStart.value.length">
              <a-input
                  :placeholder="survey.translations.welcomeScreen.buttonStart.defaultValue"
                  size="large"
                  :maxLength="20"
                  v-model="survey.translations.welcomeScreen.buttonStart.value"
              />
            </VTextArea>
          </div>
        </div>
        <VProElement :needUpgrade="!survey.features.welcome_image"
                     name="upload Background Image"
                     class="flex items-center pt-1">
          <a-switch
              class="switch_positive-color"
              checked-children="On"
              un-checked-children="Off"
              :disabled="!survey.features.welcome_image"
              v-model="welcomeScreen.bgImage.isActive"
          />
          <span class="ml-3 mr-1 font-light text-base">Custom background image</span>
          <VLabel v-if="!survey.features.welcome_image" type="feature"></VLabel>
        </VProElement>
      </div>
    </div>
  </transition>
</template>

<script>
import VUpload from "@/components/VUpload";
import VTextArea from "@/components/VTextArea";
import {mapGetters} from "vuex";
import VLabel from "../../../VLabel";
import VProElement from "../../../VProElement";
import {UPDATE_SCREEN} from "../../../../store/actions.type";
import {debounce} from "lodash";

export default {
  name: "WelcomeScreen",
  components: {
    VUpload,
    VTextArea,
    VLabel,
    VProElement,
  },
  watch: {
    welcomeScreen: {
      handler: debounce(function (val) {
        this.$store.dispatch(`survey/${UPDATE_SCREEN}`, {survey_id: this.$route.params.id, data: val});
      }, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS),
      deep: true,
    },
  },
  data() {
    return {
      default_messages: {
        title: 'Welcome',
        description: 'Please take this survey and let us know about your experience.',
        btnLabel: 'Start',
      }
    }
  },
  computed: {
    ...mapGetters('survey', ['survey', 'welcomeScreen']),
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 200;
    },
  }
}
</script>
