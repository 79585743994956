<template>
  <div class="wrapper flex w-full">
    <div class="w-1/2 flex flex-col justify-between bg-white px-24 pt-16 pb-8"
         style="filter: drop-shadow(2px 0px 3px rgba(0, 0, 0, 0.05));"
    >

      <div class="flex mb-8">
        <a :href="landingUrl" target="_blank" class="inline mx-auto">
          <VIcon name="main-logo"/>
        </a>
      </div>

      <div style="max-width: 305px;">
        <h1 class="font-bold mb-12" style="font-size: 2.5rem; line-height: 2.625rem;">{{ title }}</h1>
        <slot></slot>
      </div>

      <div class="mt-12" style="max-width: 397px;">
        <slot name="badges"></slot>
      </div>

    </div>

    <div class="w-1/2 relative flex justify-end xl:justify-center items-center">
      <slot name="illustration"></slot>
    </div>

  </div>
</template>

<script>
import VIcon from "./VIcon";

export default {
  name: "VAuthBlock",
  props: {
    title: {
      type: String
    }
  },
  components: {VIcon},
  data() {
    return {
      landingUrl: process.env.VUE_APP_LANDING_URL
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  background-color: @v-color-blue-50;
}
</style>
