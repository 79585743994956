<template>
  <div>
    <div class="flex items-start pl-12">
      <div class="flex flex-grow">
        <span class="text-xl font-light">Question:</span>
        <VTextArea :max-length="questionMaxLength"
                   :count-typed-symbols="question.text.length"
                   :has-upgrade="true"
                   class="flex-grow ml-3">
          <a-textarea placeholder="Your question here"
                      :max-length="questionMaxLength"
                      :auto-size="{ minRows: 3 }"
                      v-model="question.text"
          />
        </VTextArea>
      </div>
      <div class="ml-5 flex-none space-y-5">

        <div class="flex items-center">
          <div class="flex items-center">
            <span class="text-xl font-light">Type</span>
            <a-tooltip class="ml-1 mr-2">
              <template slot="title">
                Input field with a built-in validator.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
          <a-select v-model="question.subtype" style="width: 145px" size="large">
            <a-select-option
                v-for="subtype in subtypes"
                :key="subtype.slug"
                :value="subtype.slug"
            >
              {{ subtype.title }}
            </a-select-option>
          </a-select>
        </div>

        <div class="flex items-center space-x-3">
          <span class="font-light text-xl">Required</span>
          <a-switch
              class="switch_positive-color"
              checked-children="On"
              un-checked-children="Off"
              v-model="question.options.required"
          />
        </div>

      </div>
    </div>

    <div>
      <div class="flex space-x-3">
        <span class="text-xl font-light w-32 text-right">Placeholder:</span>
        <VTextArea :max-length="35" :count-typed-symbols="question.options.placeholder.length" style="width: 208px;">
          <a-input :placeholder="defaultMessages.placeholder[question.subtype]"
                   size="large"
                   :maxLength="35"
                   v-model="question.options.placeholder"
          />
        </VTextArea>
      </div>

      <div class="flex space-x-3">
        <span class="text-xl font-light w-32 text-right">Button label:</span>
        <VTextArea :max-length="20" :count-typed-symbols="question.options.btnLabel.length" style="width: 208px;">
          <a-input
              :placeholder="survey.translations.questions.open.buttonSubmit.value || survey.translations.questions.open.buttonSubmit.defaultValue"
              size="large"
              :maxLength="20"
              v-model="question.options.btnLabel"
          />
        </VTextArea>
      </div>
    </div>

    <a-divider style="margin-top: 32px; margin-bottom: 16px; border-color: var(--color-gray-300)"/>

  </div>
</template>

<script>
import VTextArea from "../../../VTextArea";
import questionMixin from "../../../../mixins/question";
import {mapGetters} from "vuex";

export default {
  name: "QuestionOpen",
  props: ["question"],
  components: {
    VTextArea,
  },
  mixins: [questionMixin],
  data() {
    return {
      subtypes: [
        {
          slug: "singleLine",
          title: "Single line"
        },
        {
          slug: "multiLine",
          title: "Multi line"
        },
        {
          slug: "number",
          title: "Number"
        },
        {
          slug: "email",
          title: "Email"
        },
        {
          slug: "phone",
          title: "Phone"
        },
      ],
    }
  },
  computed: {
    ...mapGetters('survey', ['survey']),
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 100;
    },
    defaultMessages: function () {
      return {
        placeholder: {
          singleLine: "Type answer here",
          multiLine: "Type answer here",
          number: "Type number here",
          email: "you@yourcompany.com",
          phone: "Type phone number",
        },
      }
    },
  }
}
</script>

<style scoped>

</style>
