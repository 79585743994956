<template>
  <VAuthBlock :title="!hasPromoCode ? $route.meta.heading || 'Start For Free Today!' : 'Register with a Code'">
    <a-form :form="form" @submit="register">

      <div v-if="!hasPromoCode">
        <a-form-item class="mb-6">
          <a-button
              type="default"
              block
              size="large"
              class="btn-action"
              @click="makeSocialAuth('google')"
          >
            <a-icon :component="iconGoogle"></a-icon>
            Continue with Google
          </a-button>
        </a-form-item>

        <a-divider/>
      </div>

      <a-form-item :validate-status="nameError() ? 'error' : ''" :help="nameError() || ''" class="mt-6">
        <a-input
            size="large"
            placeholder="Name and Last name"
            name="name"
            v-decorator="['name', { rules: [{ required: true, message: 'Please input your Name' }] }]"
        >
          <a-icon slot="prefix" type="user" style="color: var(--color-gray-300)"/>
        </a-input>
      </a-form-item>
      <a-form-item
          :validate-status="emailError() ? 'error' : ''"
          :help="emailError() || ''"
      >
        <a-input
            size="large"
            placeholder="Email"
            v-decorator="['email', { rules: [{type: 'email', message: 'The input is not valid E-mail'}, { required: true, message: 'Please input your Email' }], initialValue: $route.query.email ? decodeURIComponent($route.query.email) : '', }]"
        >
          <a-icon slot="prefix" type="mail" style="color: var(--color-gray-300)"/>
        </a-input>
      </a-form-item>
      <a-form-item :validate-status="passwordError() ? 'error' : ''" :help="passwordError() || ''">
        <a-input-password
            size="large"
            placeholder="Password"
            autocomplete="off"
            v-decorator="['password', { rules: [{ required: true, message: 'Please input your Password' }, {min: 8, message: 'Try a password with at least 8 characters'}] }]"
        >
          <a-icon slot="prefix" type="lock" style="color: var(--color-gray-300)"/>
        </a-input-password>
      </a-form-item>
      <a-form-item v-if="hasPromoCode" :validate-status="promoCodeError() ? 'error' : ''"
                   :help="promoCodeError() || ''">
        <a-input
            size="large"
            placeholder="Your code here"
            autocomplete="off"
            v-decorator="['promocode', { rules: [{ required: true, message: 'Please input your Code' }] }]"
        >
          <a-icon slot="prefix" type="fire" style="color: var(--color-gray-300)"/>
        </a-input>
      </a-form-item>
      <a-form-item class="mb-0">
        <div class="flex">
          <div class="w-1/2">
            <VButton type="primary"
                     size="large"
                     html-type="submit"
                     :disabled="hasErrors(form.getFieldsError())"
                     :loading="isLoading"
                     style="min-width: 152px;"
            >
              Register
            </VButton>
          </div>
          <div class="flex w-1/2 items-center justify-center">
            <VButton type="link" size="large" @click="$router.push({name: 'Login', query: $route.query})">Sign in</VButton>
          </div>
        </div>
      </a-form-item>
    </a-form>

    <template #badges>
      <span class="text-base font-light text-character_gray-300 mb-2">What people say about us:</span>
      <a-divider/>
      <div class="flex mt-5 space-x-5">
        <a href="https://www.producthunt.com/posts/metasurvey?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-metasurvey"
           target="_blank">
          <VIcon name="ph-badge"></VIcon>
        </a>
        <a href="https://www.g2.com/products/metasurvey/reviews"
           target="_blank">
          <VIcon name="g2-badge"></VIcon>
        </a>
      </div>
    </template>

    <template #illustration>
      <div class="absolute" style="left: -152px; top: calc(50% - 275px);">
        <img src="@/assets/images/cellphone-display-swipe-question.png" alt="Cellphone display swipe question"
             style="width: 300px;" class="h-auto object-contain">
      </div>
      <div class="px-24">
        <div class="max-w-xl my-auto text-center">
          <ul class="list-none text-left space-y-20">
            <li class="flex items-center space-x-5">
              <div class="flex">
                <VIcon name="flag-with-bg"/>
              </div>
              <div style="max-width: 294px">
                <h3 class="font-medium text-2xl mb-2 leading-none">Create <span class="font-bold">in minute</span></h3>
                <p class="font-light text-base mb-0">Use <span class="font-normal">templates</span> to quickly create
                  stunning
                  high-conversion surveys</p>
              </div>
            </li>
            <li class="flex items-center space-x-5">
              <div class="flex">
                <VIcon name="bag-with-bg"/>
              </div>
              <div style="max-width: 294px">
                <h3 class="font-medium text-2xl mb-2 leading-none">Make users desire <span
                    class="font-bold">your product / service</span></h3>
                <p class="font-light text-base mb-0">Get valuable <span class="font-normal">clear vision</span> of what
                  user wants</p>
              </div>
            </li>
            <li class="flex items-center space-x-5">
              <div class="flex">
                <VIcon name="smiles-with-bg"/>
              </div>
              <div style="max-width: 294px">
                <h3 class="font-medium text-2xl mb-2 leading-none">Get customer <span class="font-bold">feedback</span>
                </h3>
                <p class="font-light text-base mb-0">Improve your site / service Interface and <span
                    class="font-normal">make it effective</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </VAuthBlock>
</template>

<script>
import {REGISTER} from "@/store/actions.type";
import hasErrors from "@/helpers/formValidate";
import {mapGetters} from "vuex";
import VAuthBlock from "@/components/VAuthBlock";
import {GoogleSvg} from "@/helpers/customIcons";
import socialAuth from "@/mixins/socialAuth";
import VButton from "../components/VButton";
import VIcon from "../components/VIcon";

export default {
  name: "Register",
  components: {VAuthBlock, VButton, VIcon},
  mixins: [socialAuth],
  data() {
    return {
      hasErrors,
      form: this.$form.createForm(this, {name: 'register_form'}),
      iconGoogle: GoogleSvg,
      hasPromoCode: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });

    // Check if the referral is from AppSumo
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const hasPromoCodeInSession = window.sessionStorage.getItem('promocode')
    this.hasPromoCode = urlParams.get('promocode') === 'true' || hasPromoCodeInSession
    if (this.hasPromoCode && !hasPromoCodeInSession) {
      window.sessionStorage.setItem('promocode', true)
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoading'])
  },
  methods: {
    // Only show error after a field is touched.
    emailError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('email') && getFieldError('email');
    },
    // Only show error after a field is touched.
    passwordError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('password') && getFieldError('password');
    },
    // Only show error after a field is touched.
    nameError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('name') && getFieldError('name');
    },
    // Only show error after a field is touched.
    promoCodeError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('promocode') && getFieldError('promocode');
    },
    register(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch(`auth/${REGISTER}`, {
            ...values,
            invitationToken: this.$route.query?.invitation_token,
          })
              .then(() => {
                this.$router.push('/');
              })
        }
      });
    },
  },
}
</script>

<style scoped>

</style>
