import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/views/Dashboard";
import SurveyEdit from "@/views/SurveyEdit";
import Login from "@/views/Login";
import Register from "@/views/Register";
import store from "@/store";
import {CHECK_AUTH, CONFIRM_EMAIL, SET_AUTH_GOOGLE} from "../store/actions.type";
import {PURGE_AUTH} from "../store/mutations.type";
import JwtService from "@/common/jwt.service";
import NProgress from "nprogress";
import PageNotFound from "@/views/PageNotFound";
import ForgotPassword from "@/views/ForgotPassword";
import ResetPassword from "@/views/ResetPassword";
import Subscription from "@/views/Subscription";
import Upgrade from "@/views/Upgrade";
import Settings from "@/views/Settings";
import ChurnSurvey from "../views/ChurnSurvey";
import {message} from "ant-design-vue"

require('nprogress/nprogress.css');

Vue.use(VueRouter);
Vue.use(NProgress);

NProgress.configure({showSpinner: false});

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: Dashboard,
      name: 'Index',
      meta: {
        title: `Dashboard – ${process.env.VUE_APP_BASE_TITLE}`,
        pageTitle: 'Surveys',
      }
    },
    {
      path: "/folder/:folderId",
      component: Dashboard,
      name: "Folder",
      meta: {
        title: `Folder – ${process.env.VUE_APP_BASE_TITLE}`,
        pageTitle: 'Untitled folder',
      }
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
      meta: {
        title: `Login – ${process.env.VUE_APP_BASE_TITLE}`
      }
    },
    {
      path: "/signup",
      component: Register,
      name: "Register",
      meta: {
        title: `Register – ${process.env.VUE_APP_BASE_TITLE}`
      }
    },
    {
      path: "/password-recover",
      component: ForgotPassword,
      name: "ForgotPassword",
      meta: {
        title: `Forgot password – ${process.env.VUE_APP_BASE_TITLE}`
      }
    },
    {
      path: "/password-reset/:token",
      component: ResetPassword,
      name: "ResetPassword",
      meta: {
        title: `Reset password – ${process.env.VUE_APP_BASE_TITLE}`
      }
    },
    {
      path: "/confirm-email/:code",
      name: "RegisterConfirm",
      async beforeEnter(to, from, next) {
        const {code} = to.params || {};

        if (code) {
          store.dispatch(`auth/${CONFIRM_EMAIL}`, {code})
            .then(() => {
              message.success('Email confirmed successfully!');
            });
        }

        next({name: 'Index', replace: true});
      }
    },
    {
      path: "/invite",
      component: Register,
      name: "AcceptInvite",
      meta: {
        title: `Accept Invitation – ${process.env.VUE_APP_BASE_TITLE}`,
        heading: "Sign up or log in to accept invitation",
      },
      async beforeEnter(to, from, next) {
        const {email, invitation_token} = to.query || {};
        const user = store.getters["auth/user"];

        if (!email || !invitation_token) {
          return next();
        }

        if (user.email !== email) {
          store.commit(`auth/${PURGE_AUTH}`);
          return next();
        }

        const key = 'invite-accepting';
        message.loading({
          content: 'Accepting invitation...',
          duration: 0,
          key
        });

        try {
          await store.dispatch("collaborators/acceptInvitation", {
            email,
            invitationToken: invitation_token
          });
          message.success({
            content: 'Invitation accepted!',
            key
          });
        } finally {
          next({name: 'Index', replace: true}); // always redirect to dashboard, because back button in survey/folder can't work
        }
      }
    },
    {
      path: "/survey/:id",
      component: SurveyEdit,
      name: "Survey",
    },
    {
      path: "/churn-survey",
      component: ChurnSurvey,
      name: "ChurnSurvey",
      meta: {
        title: "Why did you unsubscribe?"
      }
    },
    {
      path: "/subscription",
      component: Subscription,
      name: 'Billing',
      meta: {
        title: `Your subscription – ${process.env.VUE_APP_BASE_TITLE}`
      },
      async beforeEnter(to, from, next) {
        const {current_plan, oldPlan} = store.getters["auth/user"];

        if (current_plan !== 'free' || oldPlan) {
          next()
        } else {
          next({name: "Upgrade"})
        }
      }
    },
    {
      path: "/upgrade",
      component: Upgrade,
      name: 'Upgrade',
      meta: {
        title: `Upgrade to PRO – ${process.env.VUE_APP_BASE_TITLE}`
      },
      async beforeEnter(to, from, next) {
        const {current_plan, oldPlan} = store.getters["auth/user"];

        if (current_plan === 'free' && !oldPlan) {
          next()
        } else {
          next({name: "Billing"})
        }
      }
    },
    {
      path: "/settings",
      component: Settings,
      name: 'Settings',
      meta: {
        title: `Settings – ${process.env.VUE_APP_BASE_TITLE}`
      },
    },
    {
      path: "/google-auth",
      name: "GoogleAuth",
      async beforeEnter(to, from, next) {
        const {code, state} = to.query || {};
        const invitationToken = state ? JSON.parse(decodeURIComponent(state))?.invitationToken : null;

        if (code) {
          try {
            await store.dispatch(`auth/${SET_AUTH_GOOGLE}`, {code, invitationToken});
          } catch {
            message.error('Google Auth Error! Please try again or use alternative auth way.');
          }
        }

        next({name: 'Login'});
      }
    },
    {
      path: "/connect",
      children: [
        {
          path: "google",
          beforeEnter: (to, from, next) => {
            const queryParams = new URLSearchParams(window.location.search)

            window.opener.postMessage(
              {
                surveyId: queryParams.has('state') ? queryParams.get('state') : null,
                code: queryParams.has('code') ? queryParams.get('code') : null,
              },
              window.location.origin,
            )

            window.close()

            next(false);
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/404',
      component: PageNotFound,
      name: 'NotFound',
      meta: {
        title: "NOT FOUND"
      }
    }

  ],
  mode: 'history'
})

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  if (to.meta.title) {
    window.document.title = to.meta.title;
  }

  await store.dispatch(`auth/${CHECK_AUTH}`)
    .then(() => {
      let isAuthenticated = !!JwtService.getToken();

      if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'ForgotPassword' && to.name !== 'ResetPassword' && to.name !== 'RegisterConfirm' && to.name !== 'GoogleAuth' && to.name !== 'AcceptInvite' && !isAuthenticated) next({name: "Login"});
      else if ((to.name === 'Login' || to.name === 'Register') && isAuthenticated) next({path: "/"});
      else next();

      NProgress.done();
    })
    .catch(() => {
      next({name: "Login"});
    });
});

router.afterEach(() => {
  // scroll to top
  window.scrollTo(0, 0);

  if (Vue.prototype.$posthog) {
    Vue.prototype.$posthog.capture("$pageview", {
      $current_url: window.location.href
    });
  }
})

export default router;
