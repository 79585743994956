import { render, staticRenderFns } from "./ThankyouScreen.vue?vue&type=template&id=909ef824&scoped=true&"
import script from "./ThankyouScreen.vue?vue&type=script&lang=js&"
export * from "./ThankyouScreen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "909ef824",
  null
  
)

export default component.exports