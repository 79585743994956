<template>
  <div class="flex">
    <a href="https://www.producthunt.com/posts/metasurvey?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-metasurvey"
       target="_blank"
       @click="$posthog.capture('Clicked on Badge', {badgeName: 'ProductHunt'})">
      <VIcon name="ph-badge"></VIcon>
    </a>
    <a href="https://www.g2.com/products/metasurvey/reviews"
       target="_blank"
       @click="$posthog.capture('Clicked on Badge', {badgeName: 'G2'})">
      <VIcon name="g2-badge"></VIcon>
    </a>
  </div>
</template>

<script>
import VIcon from "./VIcon";

export default {
  name: "VBadges",
  components: {VIcon}
}
</script>

<style scoped>

</style>
