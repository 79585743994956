<template>
  <div class="bg-white border border-solid border-neutral_gray-300 p-6 rounded">

    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center">
        <a-switch
            class="switch_positive-color"
            checked-children="On"
            un-checked-children="Off"
            v-model="item.active"
        />
        <h3
            class="inline ml-6 mb-0 text-2xl font-light"
            :class="{'text-gray-300': !item.active}">
          <slot name="title"></slot>
        </h3>
      </div>
      <a-popconfirm
          title="Are you sure delete this logic?"
          ok-text="Delete"
          cancel-text="No, cancel"
          @confirm="onDelete"
          overlayClassName="danger-confirm"
      >
        <a-icon slot="icon" type="question-circle-o" style="color: var(--color-negative)"/>
        <a-button
            type="link"
            size="large"
            icon="delete"
            class="btn-action_question btn-negative -mr-3"
            style="height: auto;"
        >
          Delete
        </a-button>
      </a-popconfirm>
    </div>

    <div class="space-y-8">
      <div class="space-y-6">
        <div v-for="(condition, index) in conditions" :key="index">
          <div class="flex items-center space-x-3">
            <div v-if="index === 0" class="text-xl font-light flex-none text-right" style="width: 90px;">
              If <span class="font-semibold">answer</span>
            </div>
            <div v-else style="width: 90px;">
              <a-select v-model="condition.operator" style="width: 90px" size="large">
                <a-select-option
                    v-for="operator in operators"
                    :key="operator"
                >
                  {{ operator.toUpperCase() }}
                </a-select-option>
              </a-select>
            </div>
            <a-select v-model="condition.trigger"
                      :style="condition.trigger !== 'is empty' && condition.trigger !== 'is not empty' ? 'width: 156px; flex: none;' : 'width: 100%'"
                      size="large">
              <a-select-option
                  v-for="trigger in triggers"
                  :key="trigger"
              >
                {{ trigger }}
              </a-select-option>
            </a-select>

            <div v-show="condition.trigger !== 'is empty' && condition.trigger !== 'is not empty'" class="w-full">
              <a-select v-if="questionTypes[question.type].fieldType === 'select'" v-model="condition.value"
                        size="large"
                        style="width: 100%;">
                <a-select-option
                    v-for="option in questionTypes[question.type].selectOptions"
                    :key="option._id || option"
                >
                  {{ option.value || option }}
                </a-select-option>
              </a-select>
              <a-input v-else
                       size="large"
                       placeholder="Value"
                       v-model="condition.value"
              />
            </div>

            <VButton v-show="conditions.length > 1"
                     type="default-negative-hover"
                     size="large"
                     style="height: 40px; width: 40px; font-size: 20px;"
                     class="ml-3"
                     @click="deleteCondition(index)"
            >
              <div class="flex">
                <VIcon name="clear"/>
              </div>
            </VButton>
          </div>
        </div>
        <a-button
            size="large"
            icon="plus"
            block
            class="btn-add"
            style="text-transform: uppercase;"
            @click="addCondition"
        >
          Add condition
        </a-button>
      </div>
      <div class="flex items-center space-x-3">
        <div class="text-xl font-light flex-none text-right" style="width: 90px;">
          Then <span class="font-semibold">go to</span>
        </div>
        <a-select v-model="goto" size="large" class="w-full">
          <a-select-option
              v-for="question in questionsComputed"
              :key="question._id"
          >
            <span v-if="question.type !== undefined"
                  :style="`color: ${getQuestionTypeColor(question.type)}`">
              [{{ question.type }}]
            </span>
            {{ question.text | truncateQuestionText }}
          </a-select-option>
        </a-select>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VButton from "../VButton";
import VIcon from "../VIcon";
import _ from "lodash";

const LAST_STEP_COMMENT = "lastStepComment";

export default {
  name: "VConditionalLogicItem",
  components: {VButton, VIcon},
  props: {
    item: Object,
    question: Object,
  },
  filters: {
    truncateQuestionText(value) {
      if (value.trim() === "") return "Untitled"
      return _.truncate(value, {length: 50})
    }
  },
  created() {
    // set default conditions
    const selectOptions = this.getSelectOptions(this.question.type);
    if (this.item.conditions.length) {
      this.conditions = this.item.conditions;
    } else {
      this.conditions.push({
        trigger: this.triggers[0],
        value: selectOptions ? selectOptions[0]?._id || selectOptions[0] : null
      });
    }

    // set default goto
    if (this.item.action.value !== undefined) {
      this.goto = this.item.action.value;
    } else {
      const lastQuestionId = this.survey.comment.active ? LAST_STEP_COMMENT : this.thankyouScreens?.[0]?._id ? `thankyou-${this.thankyouScreens[0]._id}` : null;
      const index = this.questions.findIndex((item) => item._id === this.question._id);
      this.goto = index !== -1 && index < this.questions.length - 1 ? this.questions[index + 1]._id : lastQuestionId;
    }
  },
  data() {
    return {
      conditions: [],
      goto: null,
      operators: ["and", "or"],
    }
  },
  watch: {
    triggers: {
      handler: function () {
        this.conditions.map((condition) => condition.trigger = this.triggers[0])
      },
    },
    conditions: {
      handler: function (newValue) {
        this.$emit("update", {conditions: newValue})
      },
      deep: true
    },
    goto: {
      handler: function (newValue) {
        this.$emit("update", {goto: newValue})
      },
    },
  },
  computed: {
    ...mapGetters('survey', ['survey', 'questions', 'thankyouScreens']),
    questionsComputed() {
      const questions = this.questions.filter((q) => q._id !== this.question._id);

      if (this.survey.comment.active) {
        questions.push({text: "Last step comment", _id: LAST_STEP_COMMENT});
      }

      const thankyouScreenItems = this.thankyouScreens.map((t) => {
        return {text: `${t.title} ${t.description}`, _id: `thankyou-${t._id}`, type: "thank you"}
      });

      return questions.concat(thankyouScreenItems);
    },
    questionTypes() {
      return {
        swipe: {
          triggers: ["is", "is not"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("swipe"),
        },
        nps: {
          triggers: ["=", "≠", ">", "<", "≥", "≤"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("nps"),
        },
        multiple: {
          triggers: ["contains", "does not contain"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("multiple"),
          subtypes: {
            single: {
              triggers: ["is", "is not"],
            }
          },
        },
        rating: {
          triggers: ["=", "≠", ">", "<", "≥", "≤"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("rating"),
        },
        open: {
          subtypes: {
            singleLine: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input",
            },
            multiLine: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input",
            },
            number: {
              triggers: ["=", "≠", ">", "<", "≥", "≤", "is empty", "is not empty"],
              fieldType: "input",
            },
            email: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input",
            },
            phone: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input"
            },
          }
        },
      }
    },
    triggers() {
      let triggers = [];

      if (this.question.type === "multiple" && this.question.options.isSingleSelection) {
        triggers = this.questionTypes.multiple.subtypes.single.triggers;
      } else if (this.question.type === "open") {
        triggers = _.get(this.questionTypes.open.subtypes, this.question.subtype, null)?.triggers;
      } else {
        triggers = _.get(this.questionTypes, this.question.type, null)?.triggers;
      }

      return triggers;
    },
  },
  methods: {
    onDelete() {
      this.$emit("delete")
    },
    addCondition() {
      const selectOptions = this.getSelectOptions(this.question.type);
      this.conditions.push({
        trigger: this.triggers[0],
        value: selectOptions ? selectOptions[0]?._id || selectOptions[0] : null,
        operator: "and"
      });
    },
    deleteCondition(index) {
      this.conditions.splice(index, 1);
    },
    getSelectOptions(type) {
      let selectOptions = null;
      if (type === "swipe") {
        selectOptions = [
          {
            _id: "yes",
            value: this.question.translations.rightButton.value || this.survey.translations.questions.swipe.rightButton.value || this.survey.translations.questions.swipe.rightButton.defaultValue
          },
          {
            _id: "no",
            value: this.question.translations.leftButton.value || this.survey.translations.questions.swipe.leftButton.value || this.survey.translations.questions.swipe.leftButton.defaultValue
          },
        ]
      } else if (type === "multiple") {
        selectOptions = this.question.choices.map((choice, index) => {
          return {
            ...choice,
            value: `[${index + 1}] ${choice.text || "Untitled choice"}`
          };
        });
      } else if (type === "nps") {
        selectOptions = _.range(1, this.question.options.scoreScale + 1);
      } else if (type === "rating") {
        switch (this.question.options.ratingScale) {
          case(2):
            selectOptions = [1, 5]
            break;
          case(3):
            selectOptions = [1, 3, 5]
            break;
          default:
            selectOptions = [1, 2, 3, 4, 5]
        }
      }
      return selectOptions;
    },
    getQuestionTypeColor(type) {
      switch (type) {
        case "swipe":
          return "var(--color-blue-500)"
        case "nps":
          return "var(--color-violet-500)"
        case "rating":
          return "var(--color-orange-500)"
        case "multiple":
          return "var(--color-blue-700)"
        case "open":
          return "var(--color-gray-500)"
        case "thank you":
          return "var(--color-positive)"
      }
    }
  }
}
</script>

<style scoped>

</style>
