<template>
  <transition
      enter-active-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="opacity-100"
      leave-to-class="opacity-0"
  >
    <div
        v-if="thankyouScreen"
        v-show="thankyouScreen.isActive"
        class="flex items-start transition-opacity ease-in-out duration-300">
      <div v-if="thankyouScreen.image.type !== 'no'" class="flex-none">
        <div v-if="thankyouScreen.image.type === 'default'">
          <h4 class="flex items-center text-xl font-light mb-1">
            Default image:
          </h4>
          <div class="mr-8 flex-none">
            <div class="flex justify-center items-center bg-gray-50 rounded"
                 style="border: 1px solid #d9d9d9; height: 300px; width: 300px;">
              <a-icon type="check-circle"
                      style="font-size: 50px;"
                      :style="defaultIconStyle"></a-icon>
            </div>
          </div>
        </div>
        <div v-else>
          <h4 class="flex items-center text-xl font-light mb-1">
            Custom image:
            <VProElement
                :needUpgrade="!survey.features.thankyou_image"
                name="upload Custom Image">
              <VLabel v-if="!survey.features.thankyou_image" type="feature"></VLabel>
            </VProElement>
          </h4>
          <VProElement
              :needUpgrade="!survey.features.thankyou_image"
              name="upload Custom Image"
              :class="{'opacity-40': !survey.features.thankyou_image}">
            <VUpload
                class="mr-8 flex-none"
                uploader-type="thankyou-screen"
                :image-src.sync="thankyouScreen.image.src"
                :is-disabled="!survey.features.thankyou_image"
            />
          </VProElement>
        </div>
      </div>


      <div class="flex-grow">
        <div class="space-y-1">
          <div :class="{'flex': thankyouScreen.image.type === 'no'}">
            <div :class="{'w-28 text-right': thankyouScreen.image.type === 'no'}">
              <h4 class="text-xl font-light mb-1 mr-3">Title:</h4>
            </div>
            <div class="flex-grow">
              <VTextArea :max-length="50" :count-typed-symbols="thankyouScreen.title.length">
                <a-input
                    :placeholder="default_messages.title"
                    size="large"
                    :maxLength="50"
                    v-model="thankyouScreen.title"
                />
              </VTextArea>
            </div>
          </div>


          <div :class="{'flex': thankyouScreen.image.type === 'no'}">
            <div :class="{'w-28 text-right': thankyouScreen.image.type === 'no'}">
              <h4 class="text-xl font-light mb-1 mr-3">Description:</h4>
            </div>
            <div class="flex-grow">
              <VTextArea :max-length="questionMaxLength"
                         :count-typed-symbols="thankyouScreen.description.length"
                         :has-upgrade="true">
                <a-textarea :placeholder="default_messages.description"
                            :max-length="questionMaxLength"
                            :auto-size="{ minRows: 3 }"
                            v-model="thankyouScreen.description"
                />
              </VTextArea>
            </div>
          </div>


          <div class="pb-6">
            <h4 class="text-xl font-light mb-4">Image:</h4>
            <div class="flex">
              <a-radio-group v-model="thankyouScreen.image.type" @change="onChangeImageType">
                <a-radio-button v-for="imageType in imageTypes"
                                :key="imageType.key"
                                :value="imageType.key"
                                :disabled="imageType.key === 'custom' && !survey.features.thankyou_image">
                  <span v-if="imageType.key !== 'custom'" class="font-light text-base">{{ imageType.name }}</span>
                  <VProElement v-else
                               :needUpgrade="!survey.features.thankyou_image"
                               name="upload Custom Image"
                  >
                    <span class="font-light text-base">Custom image</span>
                  </VProElement>
                </a-radio-button>
              </a-radio-group>
              <VProElement :needUpgrade="!survey.features.thankyou_image"
                           name="upload Custom Image"
              >
                <VLabel v-if="!survey.features.thankyou_image" type="feature"></VLabel>
              </VProElement>
            </div>
          </div>


          <div>
            <h4 class="text-xl font-light mb-4">Ending actions:</h4>

            <div class="space-y-4">
              <div class="flex">
                <div class="w-full items-center">
                  <VProElement :needUpgrade="!survey.features.cta"
                               name="add Call-To-Action Button"
                               class="flex items-center"
                  >
                    <a-switch class="switch_positive-color"
                              checked-children="On"
                              un-checked-children="Off"
                              :disabled="!survey.features.cta"
                              v-model="thankyouScreen.button.isActive"
                              @change="onCta"
                    />
                    <span class="ml-3 mr-1 font-light text-base">CTA button</span>
                    <a-tooltip>
                      <template slot="title">
                        Add a Call-To-Action button to your Thank You Screen that sends respondents to your website.
                      </template>
                      <a-icon type="info-circle" class="icon-info"/>
                    </a-tooltip>
                    <VLabel v-if="!survey.features.redirect" type="feature"></VLabel>
                  </VProElement>
                  <div v-if="thankyouScreen.button.isActive && survey.features.cta" class="mb-2 mt-1">
                    <div class="w-5/12">
                      <h4 class="text-base font-light mb-1">Button label:</h4>
                      <div class="w-full max-w-48">
                        <VTextArea :max-length="25" :count-typed-symbols="thankyouScreen.button.text.length">
                          <a-input
                              size="large"
                              :placeholder="default_messages.btnLabel"
                              :maxLength="25"
                              v-model="thankyouScreen.button.text"
                          />
                        </VTextArea>
                      </div>
                    </div>
                    <div class="-mt-3">
                      <h4 class="text-base font-light mb-1">Button link:</h4>
                      <div class="w-full">
                        <a-textarea :placeholder="default_messages.btnLink"
                                    :rows="1"
                                    v-model="thankyouScreen.button.link"
                                    style="min-height: 44px; padding-top: 8px;"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-full items-center">
                  <VProElement :needUpgrade="!survey.features.redirect"
                               name="add Auto Redirect"
                               class="flex items-center"
                  >
                    <a-switch class="switch_positive-color"
                              checked-children="On"
                              un-checked-children="Off"
                              :disabled="!survey.features.redirect"
                              v-model="thankyouScreen.redirect.isActive"
                              @change="onRedirect"
                    />
                    <span class="ml-3 mr-1 font-light text-base">Auto redirect</span>
                    <a-tooltip>
                      <template slot="title">
                        The page location will change to the following address once the survey has been
                        completed.
                      </template>
                      <a-icon type="info-circle" class="icon-info"/>
                    </a-tooltip>
                    <VLabel v-if="!survey.features.redirect" type="feature"></VLabel>
                  </VProElement>
                  <div v-if="thankyouScreen.redirect.isActive && survey.features.redirect" class="mt-2">
                    <a-textarea :placeholder="landingUrl"
                                :rows="1"
                                v-model="thankyouScreen.redirect.link"
                                style="min-height: 44px; padding-top: 8px;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";
import VUpload from "../../../VUpload";
import VTextArea from "../../../VTextArea";
import VLabel from "../../../VLabel";
import VProElement from "../../../VProElement";
import {SurveyBotSuccess} from "../../../../helpers/customIcons";
import {debounce} from "lodash";
import {UPDATE_SCREEN} from "../../../../store/actions.type";

export default {
  name: "ThankyouScreen",
  components: {
    VUpload,
    VTextArea,
    VLabel,
    VProElement,
  },
  props: {
    thankyouScreen: Object,
  },
  watch: {
    thankyouScreen: {
      handler: debounce(function (val) {
        this.$store.dispatch(`survey/${UPDATE_SCREEN}`, {survey_id: this.$route.params.id, data: val});
      }, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS),
      deep: true,
    },
  },
  data() {
    return {
      default_messages: {
        title: 'Thank you!',
        description: '',
        btnLabel: 'Create survey free!',
        btnLink: process.env.VUE_APP_LANDING_URL,
      },
      landingUrl: process.env.VUE_APP_LANDING_URL,
      imageTypes: [
        {key: 'no', name: 'No image'},
        {key: 'default', name: 'Default image'},
        {key: 'custom', name: 'Custom image'}
      ],
      successIcon: SurveyBotSuccess,
    }
  },
  computed: {
    ...mapGetters('survey', ['survey']),
    ...mapGetters('themes', ["currentTheme"]),
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 200;
    },
    defaultIconStyle: function () {
      const color = this.currentTheme?.settings?.titleColor || "var(--color-blue-500)";
      return {color};
    }
  },
  methods: {
    onCta() {
      this.$posthog.capture('Toggled CTA', {
        surveyId: this.survey._id,
        value: this.thankyouScreen.button.isActive,
      });
    },
    onRedirect() {
      this.$posthog.capture('Toggled Auto Redirect', {
        surveyId: this.survey._id,
        value: this.thankyouScreen.redirect.isActive,
      });
    },
    onChangeImageType() {
      this.$posthog.capture('Changed Thank You Image Type', {
        surveyId: this.survey._id,
        value: this.thankyouScreen.image.type,
      });
    }
  }
}
</script>

<style scoped>

</style>
