<template>
  <div>
    <div class="flex px-10">
      <div class="py-4 border-0 border-r border-solid border-gray-200 pr-6 flex-none">
        <h3 class="flex items-center mb-4 text-character_gray-400">
          <span class="leading-none font-light text-2xl">Your logo</span>
          <VProElement
              :needUpgrade="!survey.features.image_src"
              name="upload Your Logo">
            <VLabel v-if="!survey.features.image_src" type="feature"></VLabel>
          </VProElement>
        </h3>
        <VProElement :needUpgrade="!survey.features.image_src"
                     name="upload Your Logo"
                     :class="{'opacity-40': !survey.features.image_src}"
        >
          <VUpload
              uploader-type="company"
              :image-src.sync="survey.image_src"
              :is-disabled="!survey.features.image_src"
          />
        </VProElement>
      </div>
      <div class="py-4 pl-6 flex-grow">
        <h3 class="mb-4 text-character_gray-400 font-light text-2xl">
          General options
        </h3>
        <div class="space-y-6">
          <div class="w-1/2">
            <div class="flex items-center mb-4">
              <a-switch class="switch_positive-color"
                        checked-children="On"
                        un-checked-children="Off"
                        v-model="survey.tooltip.active"
                        @change="onHint"
              />
              <span class="ml-3 mr-1 font-light text-base">Hint for respondents</span>
              <a-tooltip>
                <template slot="title">
                  Provide a clarification for this survey to give respondents an additional context about what is
                  expected.
                </template>
                <a-icon type="info-circle" class="icon-info"/>
              </a-tooltip>
            </div>
            <VTextArea
                :max-length="100"
                size="large"
                :count-typed-symbols="survey.tooltip.value.length"
            >
              <a-textarea placeholder="We use your feedback to improve your experience."
                          :rows="3"
                          :maxLength="100"
                          :disabled="survey.tooltip.active !== true"
                          v-model="survey.tooltip.value"
              />
            </VTextArea>
          </div>
          <div class="w-1/2" style="margin-top: 10px;">
            <div class="flex items-center mb-4">
              <a-switch class="switch_positive-color"
                        checked-children="On"
                        un-checked-children="Off"
                        v-model="survey.password.active"
                        @change="onPassword"
              />
              <span class="ml-3 mr-1 font-light text-base">Password</span>
              <a-tooltip>
                <template slot="title">
                  Secure your surveys behind a password for privacy and confidentiality.
                </template>
                <a-icon type="info-circle" class="icon-info"/>
              </a-tooltip>
            </div>
            <a-input-password placeholder="Survey password"
                              :visibilityToggle="true"
                              size="large"
                              class="w-full"
                              :disabled="survey.password.active !== true"
                              v-model="survey.password.value"
            />
          </div>
          <div class="w-1/2 flex items-center">
            <a-switch
                class="switch_positive-color"
                checked-children="On"
                un-checked-children="Off"
                v-model="survey.multiple_responses"
                @change="onMultipleResp"
            />
            <span class="ml-3 mr-1 font-light text-base">Multiple responses</span>
            <a-tooltip>
              <template slot="title">
                Allow this survey to be taken more than once from the same browser.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
          <div class="w-1/2">
            <VProElement :needUpgrade="!survey.features.remove_branding"
                         text="tooltip-hint description"
                         class="flex items-center"
                         name="remove Branding">
              <a-switch :disabled="!survey.features.remove_branding"
                        class="switch_positive-color"
                        checked-children="On"
                        un-checked-children="Off"
                        v-model="survey.remove_branding"
                        @change="onRemoveBranding"
              />
              <span class="ml-3 mr-1 font-light text-base">Remove branding</span>
              <a-tooltip>
                <template slot="title">
                  Remove MS logo from this survey to create a more seamless experience for your respondents.
                </template>
                <a-icon type="info-circle" class="icon-info"/>
              </a-tooltip>
              <VLabel v-if="!survey.features.remove_branding" type="feature"></VLabel>
            </VProElement>
          </div>
        </div>
      </div>
    </div>

    <SurveyDesignThemes/>

    <SurveyDesignTranslations/>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import VUpload from "@/components/VUpload";
import VTextArea from "@/components/VTextArea";
import VProElement from "@/components/VProElement";
import VLabel from "../../VLabel";
import SurveyDesignTranslations from "./SurveyDesignTranslations";
import SurveyDesignThemes from "./SurveyDesignThemes";

export default {
  name: "SurveyDesignOptions",
  components: {VUpload, VTextArea, VProElement, VLabel, SurveyDesignTranslations, SurveyDesignThemes},
  data() {
    return {
      activeEditSurveyName: false,
      customBackgroundColors: [
        'rgba(255, 255, 255, 1)',
        'rgba(244, 246, 250, 1)',
        'rgba(154, 165, 187, 1)',
        'rgba(77, 103, 136, 1)',
        'rgba(0, 149, 233, 1)',
        'rgba(0, 108, 169, 1)',
        'rgba(51, 51, 51, 1)',
      ],
    }
  },
  computed: {
    ...mapGetters('survey', ["survey"]),
    opacityValues: function () {
      return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse()
    }
  },
  methods: {
    onHint() {
      this.$posthog.capture('Toggled Survey Hint', {
        surveyId: this.survey._id,
        value: this.survey.tooltip.active,
      });
    },
    onPassword() {
      this.$posthog.capture('Toggled Survey Password', {
        surveyId: this.survey._id,
        value: this.survey.password.active,
      });
    },
    onMultipleResp() {
      this.$posthog.capture('Toggled Multiple responses', {
        surveyId: this.survey._id,
        value: this.survey.multiple_responses,
      });
    },
    onRemoveBranding() {
      this.$posthog.capture('Toggled Remove Branding', {
        surveyId: this.survey._id,
        value: this.survey.remove_branding,
      });
    },
  }
}
</script>
