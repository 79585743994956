<template>
  <VAuthBlock title="Reset your password">
    <div v-if="!recoverSuccess">
      <p class="text-sm -mt-2 mb-8">
        To reset your password, enter your email below and submit. An email will be sent to you with
        instructions about how to complete the process.
      </p>
      <a-form :form="form" @submit="recover">
        <a-form-item
            :validate-status="emailError() ? 'error' : ''"
            :help="emailError() || ''"
        >
          <a-input
              size="large"
              placeholder="Email"
              v-decorator="['email', { rules: [{type: 'email', message: 'The input is not valid E-mail'}, { required: true, message: 'Please input your Email' }] }]"
          >
            <a-icon slot="prefix" type="user" style="color: var(--color-gray-300)"/>
          </a-input>
        </a-form-item>
        <a-form-item class="mb-0">
          <div class="flex">
            <div class="w-1/2">
              <VButton type="primary"
                       size="large"
                       html-type="submit"
                       :disabled="hasErrors(form.getFieldsError())"
                       :loading="isLoading"
                       style="min-width: 152px;"
              >
                Reset password
              </VButton>
            </div>
            <div class="flex w-1/2 items-center justify-center">
              <VButton type="link" size="large" @click="$router.push({name: 'Login'})">Log in</VButton>
            </div>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div
        v-else
        class="text-base mt-16"
    >
      Reset link was sent to your email. Check your inbox or spam.
    </div>
  </VAuthBlock>
</template>

<script>
import VAuthBlock from "@/components/VAuthBlock";
import hasErrors from "@/helpers/formValidate";
import {mapGetters} from "vuex";
import {RECOVER_PASSWORD} from "@/store/actions.type";
import VButton from "../components/VButton";

export default {
  name: "ForgotPassword",
  components: {VAuthBlock, VButton},
  data() {
    return {
      recoverSuccess: false,
      hasErrors,
      form: this.$form.createForm(this, {name: 'forgot_password_form'}),
    };
  },
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },
  computed: {
    ...mapGetters('auth', ['isLoading'])
  },
  methods: {
    // Only show error after a field is touched.
    emailError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('email') && getFieldError('email');
    },
    recover(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch(`auth/${RECOVER_PASSWORD}`, values)
              .then(() => {
                this.recoverSuccess = true;
              })
              .catch(() => {
                this.recoverSuccess = false;
              })
        }
      });
    },
  },
}
</script>

<style scoped>

</style>
